import React from 'react'

type Props = {
  className?: string
  handleClick: () => any
  label: string
}

export default function LinkButton({ label, className, handleClick }: Props) {
  return (
    <button className={`primary-link-btn ${className}`} onClick={handleClick}>
      {label}
    </button>
  )
}
