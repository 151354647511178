import React, { memo } from 'react'
import { Link } from 'gatsby';

import { root, title as titleStyles, arrowTitle, separator, separatorShort, separatorArrow, marginTop, link } from './Separator.module.scss'

type Params = {
  title?: string;
  url?: string;
  hasArrow?: boolean;
}

function Separator({ title, url, hasArrow = false }: Params) {
  return (
    <div className={`${root} ${hasArrow ? marginTop : ``}`}>
      {hasArrow && (
        <>
          <div className={separator} />
          <Link
            className={link}
            to={url ?? '/'}>
            {title && <div className={hasArrow ? arrowTitle : titleStyles}>{title}</div>}
            <div className={separatorShort} />
            <div className={separatorArrow} />
          </Link>
        </>
      )}
      {!hasArrow && (
        <>
          {title && <div className={hasArrow ? arrowTitle : titleStyles}>{title}</div>}
          <div className={separator} />
        </>
      )}
    </div>
  )
}

export default memo(Separator)
