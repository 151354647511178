import React, { FC, ReactElement, useState } from 'react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useQueryParam,   StringParam } from "use-query-params";

import {
  worksList,
  worksListItem,
  worksTabBig,
  worksTabImage,
  worksTabItem,
  worksTabs as worksTabsStyles,
  worksTabSmall,
  worksTabSubTitle,
  worksTabTitle,
  title
} from './Works.module.scss'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { Link } from 'gatsby';
import LinkButton from '~components/LinkButton';

const WorksSection: FC<{ content: any }> = ({
  content
}): ReactElement => {
  const [activeCategory, setActiveCategory] = useQueryParam("cat", StringParam);

  const categories = content.categories.sort((a, b) => {
    if (b.title === 'All') return 0;
    if (a.title === 'All' || a.title < b.title) { return -1; }
    if (a.title > b.title) { return 1; }
    return 0;
  })

  const filterCategory = (cat: string) => {
    if (cat === 'All') setActiveCategory(``);
    else setActiveCategory(cat);
  }

  return (
    <div>
      {content.title && (
        <h1 className={title}>
          {documentToReactComponents(JSON.parse(content.title.raw))}
        </h1>
      )}
      <div className={worksList}>
        {categories.map((category: any, index: number) => (
          <LinkButton
            key={index}
            className={worksListItem}
            label={category.title}
            handleClick={() => { filterCategory(category.title); }}
          />
        ))}
      </div>
      <div className={worksTabsStyles}>
        {content.works
          .filter(workTab => !activeCategory || workTab.category.title.match(new RegExp(activeCategory, 'i')))
          .map((workTab: any, index: number) => {
          const image = getImage(workTab.img)
          return (
            <Link to={`/works/${workTab.slug ?? ``}`} key={index} className={`${worksTabItem} ${(index % 4 + index % 2) % 4 === 0 ? worksTabBig : worksTabSmall}`}>
              {image && (
                <GatsbyImage
                  alt={``}
                  image={image}
                  className={worksTabImage}
                />
              )}
              <div>
                <div className={worksTabTitle}>{workTab.title}</div>
                <div className={worksTabSubTitle}>{workTab.desc.desc}</div>
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default WorksSection
