// extracted by mini-css-extract-plugin
export var worksList = "Works-module--worksList--jhWGN";
export var worksListItem = "Works-module--worksListItem--1Ux37";
export var worksTabs = "Works-module--worksTabs--2ep3t";
export var worksTabItem = "Works-module--worksTabItem--cMsNN";
export var worksTabTitle = "Works-module--worksTabTitle--1EWGr";
export var worksTabImage = "Works-module--worksTabImage--2MCr3";
export var worksTabSubTitle = "Works-module--worksTabSubTitle--1-0mE";
export var worksTabBig = "Works-module--worksTabBig--4Nb4Y";
export var worksTabSmall = "Works-module--worksTabSmall--1GjW3";
export var title = "Works-module--title--3fuvl";