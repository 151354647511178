import React from 'react'

import Layout from '~components/Layout'
import LandingTemplate from '~templates/Landing'

export default function IndexPage() {
  return (
    <Layout>
      <LandingTemplate />
    </Layout>
  )
}
