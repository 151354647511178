import React, { FC, ReactElement } from 'react'
import SVG from 'react-inlinesvg'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'

import {
  root,
  title,
  wrapper,
  testimonialSection,
  testimonialCard,
  testimonialCardLine,
  testimonialCardContent,
  testimonialCardPerson,
  serviceSection,
  serviceCard,
  serviceCardTitle,
  serviceCardDesc,
} from './Landing.module.scss'
import Separator from '~components/Separator';
import Section from '~components/Section';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import WorksSection from '~templates/Works/components/Works';

const LandingTemplate: FC<unknown> = (): ReactElement => {
  const data = useStaticQuery(query);
  const landing = data.allContentfulLanding?.nodes;
  const sections = data.allContentfulSection?.nodes;

  return (
    <div className={root}>
      {landing.map((data: any, index: number) => (
        <div key={index} className={wrapper}>
          <h1 className={title}>
            {documentToReactComponents(JSON.parse(data.title.raw))}
          </h1>
          <p>{data.subtitle}</p>
          <Separator title={data.note} />
        </div>
      ))}
      {sections.sort((a: any, b: any) => a.order - b.order).map((section: any, index: number) => (
        <Section key={index} title={section.title} footerTitle={section.link?.title} footerLink={section.link?.url}>
          <div className={
            section.type === 'testimonials' ? testimonialSection :
              section.type === 'services' ? serviceSection : ``
          }>
            {section.content.map((content: any, index: number) => {
              if (section.type === 'works') {
                const sliced = {
                  ...content,
                  works: content.works.slice(0, 4)
                }
                return (
                  <WorksSection key={`works${index}`} content={sliced} />
                )
              }
              if (section.type === 'testimonials') {
                const image = getImage(content.img)
                return (
                  <div key={`testimonials${index}`} className={testimonialCard}>
                    <div className={testimonialCardLine}/>
                    <div className={testimonialCardContent}>
                      <p>{content.desc.desc}</p>
                      <div className={testimonialCardPerson}>
                        {image && (
                          <GatsbyImage
                            alt={``}
                            image={image}
                          />
                        )}
                        <div>
                          <div>{content.name}</div>
                          <div>{content.title}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              }
              if (section.type === 'services') {
                return (
                  <div key={`services${index}`} className={serviceCard}>
                    <SVG src={content.img.file.url} />
                    <div className={serviceCardTitle}>{content.title}</div>
                    <div className={serviceCardDesc}>{content.desc.desc}</div>
                  </div>
                )
              }
            })}
          </div>
        </Section>
      ))}
    </div>
  )
}

export default LandingTemplate

export const query = graphql`
query allContentfulLandingSectionsQuery {
  allContentfulSection {
    nodes {
      order
      type
      title
      link {
        id
        title
        url
      }
      content {
        ... on ContentfulWorks {
          id
          categories {
            title
          }
          works {
            order
            title
            slug
            desc {
              desc
            }
            category {
              title
            }
            img {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 98
                placeholder: TRACED_SVG
              )
            }
          }
        }
        ... on ContentfulService {
          id
          order
          title
          desc {
            desc
          }
          img {
            file {
              contentType
              url
            }
          }
        }
      }
    }
  }
  allContentfulLanding {
    nodes {
      title {
        raw
      }
      subtitle
      note
    }
  }
}
`
