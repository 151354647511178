// extracted by mini-css-extract-plugin
export var root = "Landing-module--root--2TrMi";
export var wrapper = "Landing-module--wrapper--3YXWg";
export var title = "Landing-module--title--3cdHc";
export var testimonialSection = "Landing-module--testimonialSection--HdjNx";
export var testimonialCard = "Landing-module--testimonialCard--3vhkz";
export var testimonialCardLine = "Landing-module--testimonialCardLine--3RPI7";
export var testimonialCardContent = "Landing-module--testimonialCardContent--SSdmY";
export var testimonialCardPerson = "Landing-module--testimonialCardPerson--JpXHp";
export var serviceSection = "Landing-module--serviceSection--2KOkb";
export var serviceCard = "Landing-module--serviceCard--3evBK";
export var serviceCardTitle = "Landing-module--serviceCardTitle--1FW7z";
export var serviceCardDesc = "Landing-module--serviceCardDesc--2Ff8c";